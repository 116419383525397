import axios from 'axios';

export const getLabs = async () => axios.get('/api/labs');

// eslint-disable-next-line max-len
export const assignStudentInLab = async (labId, studentList, admit) => axios.put(`/api/labs/${labId}`, { studentList, admit });

// export const getUserLabs = async () => axios.get('/api/Labs/user-Lab');

export const deleteLab = async (labId) => axios.delete(`/api/labs/${labId}`);

export const createLab = async (lab) => axios.post('/api/labs', lab);
